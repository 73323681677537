<template>
  <h1>Plano de Cargos e Salários</h1>
</template>

<script>
export default {
  name: "PlanoDeCargosESalarios"

}
</script>

<style>

</style>